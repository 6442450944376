<template>
    <div class="question-list">
        <div
            class="question-item"
            :style="itemStyle"
            :draggable="isDraggable"
            v-for="(item, index) in list"
            :key="item.theory_topic_id"
            @dragstart="dragstart(item.theory_topic_id)"
            @dragover.prevent="dragover($event, item.theory_topic_id)"
            @drop="drop(item.theory_topic_id)"
        >
            <div class="title flex align-center">
                <div class="question-name">
                    <div class="modify">【题文】 {{ index + 1 }}、</div>
                    <div v-html="item.title"></div>
                </div>
                <div class="score" v-if="isShowScore && item.score">({{ item.score | transferScore }} 分)</div>
            </div>
            <div class="options" v-if="item.options.length > 0">
                <div class="item" v-for="(optionsItem, optionsIndex) in item.options" :key="optionsIndex">
                    <span>{{ item.type | optionsTag(optionsIndex) }}、</span>
                    {{ optionsItem }}
                </div>
            </div>
            <div class="draggable-icon" v-if="isDraggable">
                <i class="el-icon-rank"></i>
            </div>

            <slot name="footer" :item="item"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "QuestionList",
    data() {
        return {
            dragsStartTarget: -1,
            dragsEndTarget: -1,
        };
    },
    props: {
        list: {
            type: Array,
            default() {
                return [];
            },
        },
        itemStyle: {
            type: Object,
            default() {
                return {};
            },
        },
        isShowScore: {
            type: Boolean,
            default: false,
        },
        isDraggable: {
            type: Boolean,
            default: false,
        },
    },
    components: {},
    filters: {
        optionsTag(type, index) {
            return type == 3 ? index + 1 : String.fromCodePoint(65 + index);
        },
        transferScore(value) {
            return (value + '').includes(".") ? parseFloat(value).toFixed(2) : value;
        }
    },
    methods: {
        dragstart(mark) {
            this.dragsStartTarget = mark;
        },
        dragover(e, index) {},
        drop(mark) {
            if (this.dragsStartTarget != mark) {
                this.$emit("exchange", {
                    start: this.dragsStartTarget,
                    end: mark,
                });
            }
        },
    },
};
</script>

<style scoped lang="scss">
.question-list {
    .question-item {
        position: relative;
        margin-bottom: 40px;
        .question-name,
        .options,
        .footer {
            padding-left: 30px;
            // padding-right: 30px;
            padding-right: 10px;
        }
        border: 1px solid #d2d2d2;
        .question-name {
            display: flex;
            margin: 26px 0;
            .modify {
                white-space: nowrap;
            }
        }
        .options {
            margin-bottom: 22px;
            .item {
                margin-bottom: 30px;
            }
            .item:last-child {
                margin-bottom: 0;
            }
        }
        .draggable-icon {
            position: absolute;
            right: 8px;
            top: 8px;
            font-size: 30px;
            color: #304156;
        }
    }
}
</style>
