<template>
    <div class="artificial-paper-list" v-if="list.length > 0">
        <QuestionList :list="list" :itemStyle="marginStyle" :isDraggable="isDraggable" @exchange="exchangeQuestion">
            <template #footer="{ item }">
                <div class="footer flex justify-between align-center">
                    <div class="result-box">
                        <div class="box" v-if="isShowRes && item.isShowRes">
                            <span class="text">答案： </span>
                            <span class="result" v-html="getRes(item)"></span>
                        </div>
                    </div>
                    <div class="paper-operate">
                        <i v-if="stepIndex == 1" class="icon el-icon-delete" @click="toggleQuestionExistState(item, false)"></i>
                        <el-button
                            v-else-if="!item.isExistList"
                            class="add"
                            icon="el-icon-plus"
                            @click="toggleQuestionExistState(item, true)"
                            >加入试卷</el-button
                        >
                        <el-button v-else @click="toggleQuestionExistState(item, false)" class="remove" icon="el-icon-minus"
                            >从试卷移除</el-button
                        >
                    </div>
                </div>
            </template>
        </QuestionList>
    </div>
</template>

<script>
import QuestionList from "./common/QuestionList.vue";
import { setTimeout } from "timers";
export default {
    name: "ArtificialPaperList",
    data() {
        return {
            //theory_topic_id
            selectQuestionList: [],
        };
    },
    components: {
        QuestionList,
    },
    props: {
        list: {
            type: Array,
            default() {
                return [];
            },
        },
        isShowRes: {
            type: Boolean,
            default: true,
        },
        stepIndex: {
            type: [String, Number],
            default: 0,
        },
        questionType: {
            type: [String, Number],
            default: -1,
        },
        exchangeQuestion: {
            type: Function,
            default() {
                return function () {};
            },
        },
    },
    computed: {
        packList() {},
        marginStyle() {
            return {
                marginBottom: this.stepIndex == 0 ? "40px" : "20px",
            };
        },
        isDraggable() {
            return this.stepIndex == 1 && this.questionType != 0;
        },
    },
    methods: {
        toggleQuestionExistState(question, state) {
            this.$emit("toggleQuestionExistState", { question, state });
        },
        toggleDeleteBox() {},
        getRes(item) {
            let isHaveOption = item.options.length > 0;
            let res = item.answer;
            let getChar = (number) => String.fromCodePoint(number);
            //1单选，2多选，3判断，4填空，5问答
            if (item.type == 1) {
                return getChar(65 + res[0]);
            } else if (item.type == 2) {
                return item.answer.map((item, index) => getChar(65 + res[index])).join("、");
            } else if (item.type == 3) {
                return item.options[res[0]];
            } else if (item.type == 4) {
                return res[0];
            } else {
                return item.analysis;
            }
        },
    },
};
</script>

<style scoped lang="scss">
.artificial-paper-list {
    flex: 1;
    padding: 0 30px;
    font-size: 14px;
    color: #333;
    .question-list {
        .footer {
            min-height: 60px;
            background-color: #f2f3fa;
            .result-box {
                box-sizing: border-box;
                padding: 10px;
            }
            .result {
                color: #1ab163;
            }
        }
        .icon {
            margin-right: 5px;
            color: #eb3800;
            font-size: 27px;
            font-weight: 700;
            cursor: pointer;
        }
    }
}
::v-deep .el-button {
    padding: 10px 7px;
}
::v-deep .paper-operate .add {
    background-color: #1122d8;
    border-color: #1122d8;
}
::v-deep .paper-operate .remove {
    background-color: #e84932;
    border-color: #e84932;
}
::v-deep .paper-operate .el-button {
    color: #fff;
    border-radius: 2px;
}
</style>
