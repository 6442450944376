<template>
    <div class="artitificia-head-step">
        <HeadPath currentPath="择题组卷"></HeadPath>
        <div class="content" :style="contentAlign">
            <div v-if="isShowStepOperateBtn" class="left-box">
                <div v-if="currentStep <= 0" class="exit" @click="exit">
                    <div class="icon">
                        <i class="iconfont">&#xe630;</i>
                    </div>
                    <div class="text">退出</div>
                </div>
                <div class="back" @click="backStep" v-else>
                    <div class="icon">
                        <i class="el-icon-back"></i>
                    </div>
                    <div class="text">上一步</div>
                </div>
            </div>
            <div class="step-box">
                <div class="step-item" v-for="(item, index) in stepData" :key="item.icon">
                    <div class="arrow-box" v-if="index % 2 == 1">
                        <ul class="dot-list">
                            <li
                                class="dot-item"
                                v-for="(item, dotIndex) in new Array(8)"
                                :key="dotIndex"
                                :style="currentStep | activeStyle(index)"
                            ></li>
                        </ul>
                        <i class="icon el-icon-arrow-right" :style="currentStep | activeStyle(index)"></i>
                    </div>
                    <div v-else class="step-tag" :style="currentStep | activeStyle(index)">
                        <i :class="['icon', item.icon]"></i>
                        <span class="text">{{ item.text }}</span>
                    </div>
                </div>
            </div>
            <div v-if="isShowStepOperateBtn" class="right-next" @click="next">
                <div class="icon">
                    <i class="el-icon-right"></i>
                </div>
                <div class="text">下一步</div>
            </div>
        </div>
    </div>
</template>

<script>
import HeadPath from "./common/HeadPath.vue";
export default {
    name: "ArtificiaPaperHead",
    data() {
        return {
            stepData: [
                {
                    text: "选题",
                    icon: "el-icon-document-add",
                },
                "dot",
                {
                    text: "设置",
                    icon: "el-icon-setting",
                },
                "dot",
                {
                    text: "预览",
                    icon: "el-icon-view",
                },
                "dot",
                {
                    text: "保存",
                    icon: "el-icon-document-checked",
                },
            ],
        };
    },
    props: {
        currentStep: {
            type: Number,
            default: -1,
        },
    },
    computed: {
        isShowStepOperateBtn() {
            return this.currentStep >= 0 && this.currentStep <= 2;
        },
        contentAlign() {
            return {
                justifyContent: this.isShowStepOperateBtn ? "space-between" : "center",
            };
        },
    },
    components: {
        HeadPath,
    },
    filters: {
        activeStyle(stepIndex, index) {
            let is = stepIndex * 2 >= index;
            return {
                color: is ? "#1122d8" : "",
                backgroundColor: is ? "#1122d8" : "",
            };
        },
    },
    methods: {
        next() {
            this.$emit("stepIndexChange", this.currentStep + 1);
        },
        exit() {
            this.$router.back();
        },
        backStep() {
            this.$emit("update:currentStep", this.currentStep - 1);
        },
    },
};
</script>

<style scoped lang="scss">
* {
    margin: 0;
    padding: 0;
}

.artitificia-head-step {
    padding: 0 30px 20px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #333;
    border-bottom: 1px solid #ebebeb;
    .content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 100px;
        font-size: 12px;
        .step-box {
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: 200;
            .step-tag {
                box-sizing: border-box;
                width: 70px;
                height: 32px;
                line-height: 32px;
                text-align: center;
                color: #fff !important;
                border-radius: 2px;
                background-color: #caccda;
                .icon {
                    margin-right: 5px;
                }
            }
            .arrow-box {
                display: flex;
                align-items: center;
                margin: 0 28px;
                color: #caccda;
                font-size: 15px;
                .dot-list {
                    display: flex;
                    overflow: hidden;
                    padding: 0;
                    .dot-item {
                        box-sizing: border-box;
                        width: 4px;
                        height: 4px;
                        margin-right: 7px;
                        border-radius: 50%;
                        background-color: #caccda;
                    }
                }
                .icon {
                    font-weight: 700;
                    background-color: #fff !important;
                }
            }
        }
        .left-box {
            text-align: center;
            cursor: pointer;
            color: #ff2000;
            min-width: 40px;
            .iconfont,
            .icon {
                transform: rotate(-90deg);
                font-size: 27px;
            }
            .back {
                color: #1122d8;
                .icon {
                    transform: rotate(0);
                }
            }
        }
        .right-next {
            min-width: 40px;
            text-align: center;
            cursor: pointer;
            color: #1122d8;
            .icon {
                font-weight: 700;
                font-size: 27px;
            }
        }
    }
}
</style>
