import { render, staticRenderFns } from "./ArtificialPaperSetScore.vue?vue&type=template&id=4369fdf5&scoped=true"
import script from "./ArtificialPaperSetScore.vue?vue&type=script&lang=js"
export * from "./ArtificialPaperSetScore.vue?vue&type=script&lang=js"
import style0 from "./ArtificialPaperSetScore.vue?vue&type=style&index=0&id=4369fdf5&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4369fdf5",
  null
  
)

export default component.exports