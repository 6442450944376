import { render, staticRenderFns } from "./ArtificialPaperPreview.vue?vue&type=template&id=034f904d&scoped=true"
import script from "./ArtificialPaperPreview.vue?vue&type=script&lang=js"
export * from "./ArtificialPaperPreview.vue?vue&type=script&lang=js"
import style0 from "./ArtificialPaperPreview.vue?vue&type=style&index=0&id=034f904d&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "034f904d",
  null
  
)

export default component.exports