<template>
    <div class="artificial-paper-set-score">
        <el-scrollbar style="height: 100%">
            <div class="paper-info-title">试卷信息</div>
            <div class="head">
                <div class="paper-name flex align-center">
                    <span class="text">试卷名称</span>
                    <el-input class="input" :value="paperName" @input="paperNameChange" placeholder=""></el-input>
                </div>
                <div class="paper-category flex align-center">
                    <span class="text">试卷分类</span>
                    <el-cascader
                        class="input"
                        :value="categoryId"
                        :options="questionLibraryList"
                        @change="questionLibraryChange"
                        clearable
                    ></el-cascader>
                </div>
                <div class="paper-total-score flex align-center">
                    <span class="text">试卷总分值</span>
                    <div class="input">
                        <el-input :value="totalScore" placeholder="" disabled>
                            <template #suffix>
                                <div class="modify flex align-center">分</div>
                            </template>
                        </el-input>
                    </div>
                </div>
            </div>
            <div class="set-score-box">
                <div class="question-type-item" v-for="(typeItem, typeIndex) in packList" :key="typeIndex">
                    <div class="type-head flex align-center">
                        <div class="left-title flex align-center" @click="toggleShowState(typeIndex)">
                            <i class="icon el-icon-arrow-right" :class="{ 'active-arrow': isShowTypeQuestion[typeIndex] }"></i>
                            <div class="title">{{ questionTypeList[typeIndex] }} ({{ typeItem.length }}题)</div>
                        </div>
                        <div class="right-score-input flex align-center">
                            <div class="input">
                                <el-input :value="typeSumScore[typeIndex]" disabled></el-input>
                            </div>
                            <span class="modify">分</span>
                        </div>
                    </div>
                    <div class="question-content" v-if="isShowTypeQuestion[typeIndex]">
                        <div class="question-item flex align-center" v-for="(itemQ, indexQ) in typeItem" :key="itemQ.theory_topic_id">
                            <div class="left-title flex align-center">
                                <i class="icon"></i>
                                <div class="title">第{{ indexQ + 1 }}题</div>
                            </div>
                            <div class="right-score-input flex align-center">
                                <div class="input">
                                    <el-input
                                        v-model="itemQ.score"
                                        @change="(value) => scoreChange(value, itemQ)"
                                        type="number"
                                    ></el-input>
                                </div>
                                <span class="modify">分</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
export default {
    name: "ArtificialPaperSetScore",
    data() {
        return {
            isShowTypeQuestion: [false, false, false, false, false],
            questionTypeList: ["单选题", "多选题", "判断题", "填空题", "问答题"],

            questionLibraryValue: "",
            scoreList: [],
        };
    },
    components: {},
    props: {
        questionLibraryList: {
            type: Array,
            default() {
                return [];
            },
        },
        questionList: {
            type: Array,
            default() {
                return [];
            },
        },
        setScore: {
            type: Function,
            default() {
                return function () {};
            },
        },
        paperName: {
            type: String,
            default: "",
        },
        categoryId: {
            type: Array,
            default: "",
        },
        totalScore: {
            type: [String, Number],
            default: 0,
        },
    },
    computed: {
        packList() {
            return this.questionList;
        },
        typeSumScore() {
            let arr = [];
            this.packList.forEach((item, index) => {
                let sum = item.reduce((sum, item) => sum * 1 + item.score * 1, 0);
                arr.push(sum.toFixed(2));
            });
            return arr;
        },
    },
    watch: {},
    methods: {
        questionLibraryChange(value) {
            this.$emit("update:categoryId", value);
        },
        /**切换题目类型的显示状态 */
        toggleShowState(typeIndex) {
            let state = this.isShowTypeQuestion[typeIndex];
            this.$set(this.isShowTypeQuestion, typeIndex, !state);
        },
        scoreChange(value, item) {
            this.$nextTick(() => {
                if (value <= 100 && value >= 0 && this.totalScore <= 100) {
                    const num = (value + '').split(".");
                    if(num[1]) {
                        num[1] = num[1] > 2 ? num[1].slice(0, 2) : num[1];
                    }
                    this.setScore(num.join(".") * 1, item);
                } else {
                    item.score = 0;
                    this.$message.error("总分不能超过100分");
                }
            })
        },
        paperNameChange(value) {
            this.$emit("update:paperName", value);
        },
    },
};
</script>

<style scoped lang="scss">
* {
    padding: 0;
    margin: 0;
}
.active-arrow {
    // transition: transform 0.2s;
    transform-origin: center;
    transform: rotate(90deg);
}
::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
}
.artificial-paper-set-score {
    box-sizing: border-box;
    margin: 0 15px 0 30px;
    border: 1px solid #d2d2d2;
    height: 100%;
    color: #333333;
    cursor: default;
    .paper-info-title {
        padding: 10px 0;
        text-align: center;
        font-size: 17px;
        border-bottom: 1px solid #eeeeee;
    }
    .head {
        padding: 12px 12px 16px 0;
        font-size: 15px;
        .flex {
            margin-bottom: 10px;
        }
        .text {
            flex: 3;
            margin-right: 8px;
            white-space: nowrap;
            text-align: end;
        }
        .input {
            flex: 7;
        }
        ::v-deep .el-input__inner {
            height: 30px;
        }
        .paper-total-score {
            margin-bottom: 0;
            ::v-deep .el-input__inner {
                width: 100px;
            }
            ::v-deep .el-input__suffix {
                position: relative;
            }
            ::v-deep .el-input {
                display: flex;
                align-items: center;
            }
            .modify {
                height: 100%;
                margin-bottom: 0;
                margin-left: 10px;
                color: #333;
            }
        }
    }
    .set-score-box {
        padding: 0 17px 0 10px;
        .question-type-item {
            margin-bottom: 7px;
            font-size: 13px;
            .question-content .question-item {
                margin-top: 7px;
            }
            .input {
                width: 60px;
                margin-right: 8px;
            }
            .left-title {
                flex: 1;
                cursor: pointer;
                .icon {
                    display: flex;
                    justify-content: center;
                    width: 18px;
                }
            }
            ::v-deep .el-input__inner {
                height: 24px;
                text-align: center;
            }
        }
    }
}
//去除number input的指示器
/* chrome */
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
/* 火狐浏览器 */
::v-deep input[type="number"] {
    -moz-appearance: textfield;
}
</style>
