<template>
    <div class="artificial-paper-operate flex justify-between align-center">
        <div class="left-search">
            <el-input
                v-model="searchKeyword"
                placeholder="请输入内容"
                clearable
                prefix-icon="el-icon-search"
                size="mini"
                @keydown.enter.native="searchHandle"
                @clear="searchHandle"
            >
            </el-input>
        </div>
        <div class="right-operate flex align-center">
            <div class="item total-question-count">
                共计 <strong class="count">{{ totalQuestionCount }}</strong> 道试题
            </div>
            <div class="item show-result-switch">
                <el-checkbox :value="isShowRes" @change="toggleResState">显示答案</el-checkbox>
            </div>
            <div class="item total-add-paper">
                <el-button class="blue-button" icon="el-icon-plus" @click="toggleAllQuestionExistState(true)">全部加入试卷</el-button>
            </div>
            <div class="item delete-question">
                <i class="icon el-icon-delete" @click="toggleDeleteBox"></i>
                <div class="corner-mark" v-if="totalCount">{{ totalCount }}</div>
                <div class="delete-box" v-if="showDeleteBox">
                    <div class="head">我的试卷</div>
                    <div class="content">
                        <div class="total-question-count">加入试题 (共{{ totalCount }}题)</div>
                        <div class="type-box">
                            <div class="type-item" v-for="(item, index) in packList" :key="index">
                                <div class="type-name">{{ questionType[typeList[index]] }}</div>
                                <div class="type-count">{{ item.length }} 题</div>
                                <i class="icon el-icon-delete" @click="deleteTypeQuestion(typeList[index])"></i>
                            </div>
                        </div>
                    </div>
                    <div class="footer" @click="openClearDialog">清空全部</div>
                </div>
            </div>
        </div>

        <Dialog ref="clearDialog" title="清空" :confirmHandler="confirmClear">
            <div class="clear-dialog">
                <i class="icon el-icon-warning"></i>
                是否清空该试卷所有试题，清空后将无法恢复
            </div>
        </Dialog>
    </div>
</template>

<script>
import Dialog from "components/admin/paperManage/common/Dialog";
export default {
    name: "ArtificialPaperOperate",
    data() {
        return {
            questionType: ["单选题", "多选题", "判断题", "填空题", "问答题"],
            searchKeyword: "",
            showDeleteBox: false,
        };
    },
    components: {
        Dialog,
    },
    props: {
        searchVal: {
            type: String,
            default: "",
        },
        isShowRes: {
            type: Boolean,
            default: true,
        },
        toggleAllQuestionExistState: {
            type: Function,
            default() {
                return function () {};
            },
        },
        totalQuestionCount: {
            type: Number,
            default: 0,
        },
        selectList: {
            type: Array,
            default() {
                return [];
            },
        },
        deleteTypeQuestion: {
            type: Function,
            default() {
                return function () {};
            },
        },
    },
    computed: {
        totalCount() {
            return this.packList.reduce((sum, item) => sum + item.length, 0);
        },
        typeList() {
            let list = [];
            this.selectList.forEach((item, index) => {
                if (item.length > 0) {
                    list.push(index);
                }
            });
            return list;
        },
        packList() {
            return this.selectList.filter((item) => item.length > 0);
        },
    },
    methods: {
        /**触发搜索 */
        searchHandle() {
            this.$emit("update:searchVal", this.searchKeyword);
        },
        /**点击删除打开的 我的试卷 */
        toggleDeleteBox() {
            this.showDeleteBox = !this.showDeleteBox;
        },
        /**模态框 */
        openClearDialog() {
            this.totalCount <= 0 ? this.$message.warning("请选择题目") : this.$refs.clearDialog.open();
        },
        closeClearDialog() {
            this.$refs.clearDialog.close();
        },
        /**模态框中确认删除 */
        confirmClear() {
            this.toggleDeleteBox();
            this.closeClearDialog();
            this.toggleAllQuestionExistState(false);
        },
        /**切换是否显示答案 */
        toggleResState(state) {
            this.$emit("update:isShowRes", state);
        },
    },
};
</script>

<style scoped lang="scss">
.artificial-paper-operate {
    padding: 7px 30px;
    margin-bottom: 20px;
    background-color: #f2f3fa;
    color: #666;
    .left-search {
        width: 350px;
    }
    .right-operate {
        .item {
            margin-left: 40px;
        }
        .total-question-count .count {
            color: #e84932;
        }
        .delete-question {
            position: relative;
            color: #e84932;
            font-size: 35px;
            .icon {
                cursor: pointer;
            }
            .corner-mark {
                position: absolute;
                right: 0;
                top: 0;
                height: 14px;
                padding: 0 7px;
                color: #fff;
                font-size: 10px;
                line-height: 14px;
                background-color: #e84932;
                border-radius: 8px;
                transform: translate(60%);
                border: 1px solid #fff;
            }
            .delete-box {
                position: absolute;
                top: 120%;
                left: 80%;
                width: 221px;
                padding: 0;
                color: #333;
                font-size: 14px;
                background-color: #fff;
                transform: translate(-100%);
                border: 1px solid #dcdcdc;
                z-index: 9;
                .head {
                    padding: 6px 14px;
                    background-color: #f2f3fa;
                }
                .content {
                    padding: 0 14px;
                    border-top: 1px solid #dcdcdc;
                    border-bottom: 1px solid #dcdcdc;
                    .total-question-count {
                        padding: 10px 0 16px 0;
                        color: #666;
                    }
                    .type-box {
                        .type-item {
                            display: flex;
                            align-items: center;
                            padding: 10px 0;
                            .type-name {
                                flex: 4;
                            }
                            .type-count {
                                flex: 6;
                            }
                            .icon {
                                color: #eb3800;
                                font-weight: 700;
                                font-size: 16px;
                            }
                        }
                        .type-item:nth-child(1) {
                            padding-top: 0;
                        }
                    }
                }
                .footer {
                    padding: 12px 0;
                    text-align: center;
                    color: #999;
                    cursor: pointer;
                }
            }
        }
    }
}
//diglog
.clear-dialog {
    margin: 50px 0;
    .icon {
        font-size: 16px;
        color: #e84932;
    }
}

//else
::v-deep .el-input--mini .el-input__inner {
    height: 32px;
}
//复选框
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #666;
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #1122d8;
    border-color: #1122d8;
}

::v-deep .el-button {
    padding: 10px 7px;
}
//button
::v-deep .blue-button {
    background-color: #1122d8;
    border-color: #1122d8;
    color: #fff;
}
</style>
