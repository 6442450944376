<template>
    <div class="artificial-paper-filter">
        <div class="main flex">
            <div class="question-type flex">
                <div class="text">题型</div>
                <ul class="question-type-list">
                    <li
                        class="question-type-item"
                        v-for="(item, index) in questionTypeList"
                        :key="item.type_id"
                        :class="{ active: questionTypeIndex == index }"
                        @click="questionTypeIndexChange(index)"
                    >
                        {{ item.type_name }}
                    </li>
                </ul>
            </div>
            <div v-if="stepIndex == 0" class="question-library-cate">
                <div class="text">题库分类</div>
                <el-cascader
                    v-model="questionLibraryValueData"
                    :options="questionLibraryList"
                    @change="questionLibraryChange"
                    clearable
                ></el-cascader>
            </div>
            <div class="second-step flex justify-between align-center" v-else>
                <div class="search">
                    <el-input
                        v-model="searchKeyword"
                        placeholder="请输入内容"
                        clearable
                        prefix-icon="el-icon-search"
                        size="mini"
                        @keydown.enter.native="searchHandle(searchKeyword)"
                        @clear="searchHandle(searchKeyword)"
                    >
                    </el-input>
                </div>
                <div class="show-result">
                    <el-checkbox :value="isShowRes" @change="toggleResState">显示答案</el-checkbox>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ArtificialPaperFilter",
    data() {
        return {
            searchKeyword: "",
            questionLibraryValueData: ''
        };
    },
    components: {},
    props: {
        questionTypeList: {
            type: Array,
            default() {
                return [];
            },
        },
        questionTypeIndex: {
            type: [Number, String],
            default: 0,
        },
        questionLibraryList: {
            type: Array,
            default() {
                return [];
            },
        },
        questionLibraryValue: {
            type: [Number, String],
            default: "",
        },
        stepIndex: {
            type: [Number, String],
            default: 0,
        },
        isShowRes: {
            type: Boolean,
            default: false,
        },
        searchVal: {
            type: String,
            default: "",
        },
    },
    methods: {
        questionTypeIndexChange(index) {
            this.$emit("update:questionTypeIndex", index);
        },
        questionLibraryChange(value) {
            let id = value[value.length - 1];
            this.$emit("questionLibraryValue", id);
        },
        toggleResState(state) {
            this.$emit("update:isShowRes", state);
        },
        searchHandle(value) {
            this.$emit("update:searchVal", value);
        },
    },
    mounted() {
        this.questionLibraryValueData = this.questionLibraryValue
    }
};
</script>

<style scoped lang="scss">
.active {
    color: #fff;
    background-color: #1122d8;
    border-color: #1122d8 !important;
}
::v-deep .el-input--mini .el-input__inner {
    height: 32px;
}
.artificial-paper-filter {
    // padding: 18px 0;
    margin-bottom: 20px;
    padding: 0 28px;
    .main {
        color: #333;
        font-size: 16px;
        .question-type {
            display: flex;
            align-items: center;
            .question-type-list {
                display: flex;
                font-size: 14px;
                color: #666;
                .question-type-item {
                    box-sizing: border-box;
                    width: 62px;
                    height: 32px;
                    margin-left: 10px;
                    text-align: center;
                    line-height: 32px;
                    border: 1px solid #d2d2d2;
                    cursor: pointer;
                    transition: all 0.3s;
                }
            }
        }
        .question-library-cate {
            display: flex;
            align-items: center;
            margin-left: 40px;
            .text {
                margin-right: 17px;
            }
        }
        .second-step {
            flex: 1;
            margin-left: 20px;
            .search {
                width: 350px;
            }
        }
    }
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #666;
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #1122d8;
    border-color: #1122d8;
}
::v-deep .el-input__inner {
    height: 30px;
}
</style>
